window.current_modal_element = '';

/**
 * Set modal default settings and set elements attributes
 * @param button
 * @param header
 * @param text
 * @param link
 * @param element
 */
window.modal_accept_set_base = function(button,header,text,link,element){
    current_modal_element = element;
    $('#modal-accept-button').css('display','inline-block');
    $('#modal-accept-result').html('');

    $('#modal-accept-button').attr('href',link);
    $('#modal-accept-header').html(header);
    $('#modal-accept-button').html(button);
    $('#modal-accept-text').html(text);
};

window.reload_inputs = function(){

        $('[data-toggle="datepicker"]').datepicker({
            autoHide: true,
            format: 'dd.mm.yyyy',
        });

        var $singleSelect = $('.c-select'),
            $singleSelectHasSearch = $('.c-select.has-search'),
            $multipleSelect = $('.c-select.c-select--multiple'),
            $disabledSelect = $('.c-select.is-disabled');

        $singleSelect.select2({
            width: '100%',
            minimumResultsForSearch: Infinity // disable search
        });

        // single select with search enabled
        $singleSelectHasSearch.select2({
            width: '100%'
        });

        // multiple select
        $multipleSelect.select2({
            width: '100%',
            multiple: true
        });

        // disabled select
        $disabledSelect.select2({
            width: '100%',
            disabled: true
        });
};

$(() => {
    /**
     * Modal Form Open
     */
    $('a[data-target="#modal-form"]').on('click', function(event) {
        event.preventDefault();
        $('#modal-form-header').html($(this).data('header'));
        $.ajax($(this).attr('href')).done(function(data){
            $('#modal-form-result').html(data);
            reload_inputs();
        }).fail(function() {
            $('#modal-form-result').html('Ошибка загрузки');
        });
    });


    /**
     * Modal accept button click
     */
    $('#modal-accept-button').on('click', function(event) {
    event.preventDefault();

    $('#modal-accept-button').css('display','none');

    $.ajax($(this).attr('href'))
        .done(function() {

            if(current_modal_element.data('function')){
                eval(current_modal_element.data('function'));
            }

            $('#modal-accept-result').html('' +
                '<div class="c-alert c-alert--success">' +
                '<i class="c-alert__icon fa fa-check-circle"></i> Данные успешно отправлены, можете закрыть это окно.' +
                '</div>');
        })
        .fail(function() {
            $('#modal-accept-result').html('' +
                '<div class="c-alert c-alert--danger">' +
                '<i class="c-alert__icon fa fa-times-circle"></i> Ошибка получения ответа от сервера, попробуйте позже.' +
                '</div>');
        });
    });
});