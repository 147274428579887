/**
 * Invoice cancel after function
 */
window.modal_invoice_cancel_after_send = function(){
    if(current_modal_element.data('type')){
        current_modal_element.html('Отозвать');
        current_modal_element.data('type',0);
        $('#invoice_row_'+current_modal_element.data('index')+' .status_badge').html('<span class="c-badge c-badge--small c-badge--warning c-tooltip c-tooltip--top">Возабнален</span>');
    }else{
        current_modal_element.html('Возобновить');
        current_modal_element.data('type',1);
        $('#invoice_row_'+current_modal_element.data('index')+' .status_badge').html('<span class="c-badge c-badge--small c-badge--secondary c-tooltip c-tooltip--top">Отменен</span>');
    }
};

/**
 * Invoice original after function
 */
window.modal_invoice_original_after_send = function(){
    if(current_modal_element.data('type')){
        current_modal_element.html('Отправлен оригинал');
        current_modal_element.data('type',0);

        if(current_modal_element.data('done')){
            $('#invoice_row_'+current_modal_element.data('index')).addClass("c-table__row--warning");
        }

        $('#invoice_row_'+current_modal_element.data('index')).removeClass("c-table__row--danger");
    }else{
        current_modal_element.html('Не отправлен оригинал');
        current_modal_element.data('type',1);
        $('#invoice_row_'+current_modal_element.data('index')).removeClass("c-table__row--warning");
        $('#invoice_row_'+current_modal_element.data('index')).removeClass("c-table__row--danger");
    }

    if(!current_modal_element.data('done')){
        $('#invoice_row_'+current_modal_element.data('index')).addClass("c-table__row--danger");
    }
};

/**
 * Act original after function
 */
window.modal_act_original_after_send = function(){
    if(current_modal_element.data('type')){
        current_modal_element.html('Отправлен оригинал');
        current_modal_element.data('type',0);
        $('#act_row_'+current_modal_element.data('index')).removeClass("c-table__row--warning").addClass("c-table__row--danger");
    }else{
        current_modal_element.html('Не отправлен оригинал');
        current_modal_element.data('type',1);
        $('#act_row_'+current_modal_element.data('index')).removeClass("c-table__row--danger c-table__row--warning").addClass("c-table__row--warning");
    }
};

/**
 * Act done after function
 */
window.modal_act_done_after_send = function(){
    if(current_modal_element.data('type')){
        current_modal_element.html('Получен оригинал');
        current_modal_element.data('type',0);
        $('#act_row_'+current_modal_element.data('index')).removeClass("c-table__row--danger").addClass("c-table__row--warning");
    }else{
        current_modal_element.html('Не получен оригинал');
        current_modal_element.data('type',1);
        $('#act_row_'+current_modal_element.data('index')).removeClass("c-table__row--danger c-table__row--warning");
    }
};

$(() => {
    /**
     * invoice cancel button click
     */
    $('.modal-invoice-cancel').on('click', function() {
        if($(this).data('type')){
            modal_accept_set_base(
                'Возобновить счет',
                'Возобновить счет №'+$(this).data('number'),
                'Вы собираетесь возобновить активность счета. Клиент не получит уведомление, вам нужно булет уведомить его самостоятельно.',
                '/billing/invoice/cancel/'+$(this).data('index'),
                $(this)
            );
        }else{
            modal_accept_set_base(
                'Отозвать счет',
                'Отозвать счет №'+$(this).data('number'),
                'Вы собираетесь отменить выставление счета клиенту. Клиент не получит уведомление, вам нужно булет уведомить его самостоятельно. Если клиент оплатит данный счет, его статут будет "оплачено". В противном случае счет будет помечен клиенту как отмененный и продолжит отображатся в списке.',
                '/billing/invoice/cancel/'+$(this).data('index'),
                $(this)
            );
        }
    });

    /**
     * invoice original button click
     */
    $('.modal-invoice-original').on('click', function() {
        if($(this).data('type')){
            modal_accept_set_base(
                'Оригинал не был отправлен',
                'Отмена отправки счета №'+$(this).data('number'),
                'Подтвердите что оригинал не был отправлен.',
                '/billing/invoice/original/'+$(this).data('index'),
                $(this)
            );
        }else{
            modal_accept_set_base(
                'Подтверджаю передачу оригинала',
                'Отправка оригинала счета №'+$(this).data('number'),
                'Подтвердите что вы отправили оригинал счета клиенту Почтой России или вручили ему оригинал документа лично.',
                '/billing/invoice/original/'+$(this).data('index'),
                $(this)
            );
        }
    });

    /**
     * Act done button click
     */
    $('.modal-act-done').on('click', function() {
        if($(this).data('type')){
            modal_accept_set_base(
                'Акт не был получен',
                'Отмена получение акта №'+$(this).data('number'),
                'Подтвердите что акта от клиента не был получен.',
                '/billing/act/done/'+$(this).data('index'),
                $(this)
            );
        }else{
            modal_accept_set_base(
                'Подтверждаю получение акта',
                'Получение оригинала акта №'+$(this).data('number'),
                'Подтвердите что вы получили оригинал акта от клиента.',
                '/billing/act/done/'+$(this).data('index'),
                $(this)
            );
        }
    });

    /**
     * invoice original button click
     */
    $('.modal-act-original').on('click', function() {
        if($(this).data('type')){
            modal_accept_set_base(
                'Оригинал не был отправлен',
                'Отмена отправки акта №'+$(this).data('number'),
                'Подтвердите что оригинал акта не был отправлен клиенту.',
                '/billing/act/original/'+$(this).data('index'),
                $(this)
            );
        }else{
            modal_accept_set_base(
                'Подтверджаю передачу оригинала',
                'Отправка оригинала акта №'+$(this).data('number'),
                'Подтвердите что вы отправили оригинал акта клиенту Почтой России или вручили ему оригинал документа лично.',
                '/billing/act/original/'+$(this).data('index'),
                $(this)
            );
        }
    });

    /**
     * act send button click
     */
    $('.modal-act-send').on('click', function() {
        modal_accept_set_base(
            'Отправить акт клиенту',
            'Отправка письма с актом №'+$(this).data('number'),
            'Подтвердите отправку акта на электронную почту клиенту.',
            '/billing/act/send/'+$(this).data('index'),
            $(this)
        );
    });

    /**
     * invoice send button click
     */
    $('.modal-invoice-send').on('click', function() {
        modal_accept_set_base(
            'Отправить счет клиенту',
            'Отправка письма со счетом №'+$(this).data('number'),
            'Подтвердите отправку счета на электронную почту клиенту.',
            '/billing/invoice/send/'+$(this).data('index'),
            $(this)
        );
    });
});