require('../scss/main.scss');

require('@fancyapps/fancybox');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');

require('@fortawesome/fontawesome-free/');
require('@fortawesome/fontawesome-free/css/all.min.css');

require('./vendor/bootstrap.bundle.min.js');
require('./vendor/chart.min.js');
require('./vendor/datepicker.min.js');
require('./vendor/dragula.min.js');
require('./vendor/dropzone.min.js');
//require('./vendor/fullcalendar.js');
require('./vendor/imagesloaded.pkgd.min.js');
require('./vendor/jquery.dataTables.js');
require('./vendor/jquery.matchHeight-min.js');
require('./vendor/jquery.min.js');
//require('./vendor/jquery.vmap.brazil.js');
//require('./vendor/moment.min.js');
require('./vendor/select2.full.min.js');

require('./other/sidebar.js');
require('./other/switch.js');
require('./other/todo.js');
require('./other/toggle.js');
require('./other/chat-dialogue.js');
require('./other/chart-custom.js');
require('./other/fullcalendar-custom.js');


require('./common/goal.js');
require('./common/js_validation.js');
require('./common/links.js');
require('./common/respond.js');
require('./common/main.js');

require('./parts/modal.js');
require('./parts/billing.js');

require('./other/main.js');
require('./pages/messages.js');