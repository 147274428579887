let links = document.querySelectorAll('[data-process-message]');
if (links) {
    links.forEach(link => {
        link.addEventListener('click', function (event) {
            event.preventDefault();
            event.stopPropagation();
            let url = link.href;
            fetch(url)
                .then(function (response) {
                    return response.json();
                })
                .then(function (json) {
                    if (json.success === true) {
                        success(link);
                        return;
                    }
                    if (json.success === false) {
                        fail(link);
                        return;
                    }
                })
                .catch(function (e) {
                    fail(e);
                    return;
                });
            return false;
        })
    })

    function success(link) {
        link.classList.remove('c-btn--danger');
        link.innerText = `Заявка успешно обработана!`;
        link.style.pointerEvents = 'none';
        return true;
    }

    function fail(link) {
        link.classList.remove('c-btn--success');
        link.classList.add('c-btn--danger');
        link.innerText = `Произошла ошибка, попробуйте еще раз`;
        return true;
    }
}